import React, { useEffect } from "react";

// 001484=Blue fc4b08=Orange

// Libraries
import { Col, Container, Navbar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Link as ScrollTo } from "react-scroll";
import { m } from 'framer-motion';

// Component
import { fadeIn } from "../../Functions/GlobalAnimations";
import { Header, HeaderNav } from "../../Components/Header/Header";
import InteractiveBanners04 from "../../Components/InteractiveBanners/InteractiveBanners04";
import FancyTextBox from '../../Components/FancyTextBox/FancyTextBox'
import CustomModal from '../../Components/CustomModal'
import Buttons from '../../Components/Button/Buttons'
import { TextAnime } from '../../Components/FancyText/FancyText'
import SideButtons from "../../Components/SideButtons";

// Data
import { fancyTextBox02 } from "../../Components/FancyTextBox/FancyTextBoxData";

const InteractiveBannersData = [
  {
    title: "AI assisted studies",
    content:
      "Bringing accurate, efficient, and consistent diagnostics to the patient",
    img: "/assets/img/featured-01.png",
    icon: "line-icon-Add-Window",
  },
  {
    title: "Care comes to the patient",
    content:
      "Agile Intelligence means anywhere and everywhere",
    img: "/assets/img/featured-02.png",
    icon: "line-icon-Add-Bag",
  },
  {
    title: "Connected and accessible",
    content:
      "Catch Digital Health believes that the future of healthcare relies on reaching all patients",
    img: "/assets/img/featured-03.png",
    icon: "line-icon-Android-Store",
  },
  {
    title: "Enhanced patient engagement",
    content:
      "Keeping patients engaged and proactive with their care reduces costly hospitalizations and increases patient longevity",
    img: "/assets/img/featured-04.png",
    icon: "line-icon-Add-Window",
  }
];

const OnePageNavigationPage = (props) => {
  
  useEffect(() => {
    let links = document.querySelectorAll(".inner-link");

    links.forEach(item => {
      item.addEventListener("click", () => {
        document.querySelector(".navbar-toggler:not(.collapsed)")?.click()
      })
    })
  }, [])

  return (
    <div style={props.style}>
      {/* Header Start */}
      <Header topSpace={{ desktop: false, md: true }} type="always-fixed" className="one-page-navigation border-b border-[#ffffff1a]">
        <HeaderNav theme="dark" fluid="lg" containerClass="sm:px-0" className="py-[0px]">
          <Col className="col-auto col-sm-6 col-lg-2 me-auto ps-lg-0">
            <Link aria-label="header logo" className="flex items-center md:mb-[40px]" to="/">
              <Navbar.Brand className="font-serif inline-block p-0 m-0 tracking-[-1px]">
		<img className="default-logo d-inline-block align-text-center" width="96" loading="lazy" src='/assets/img/CatchDigitalHealth-logo-white-small.png' alt='logo' />
	  	<img className="alt-logo d-inline-block align-text-center" width="96" loading="lazy" src='/assets/img/CatchDigitalHealth-logo-white-small.png' alt='logo' />
	        <img className="mobile-logo d-inline-block align-text-center" width="96" loading="lazy" src='/assets/img/CatchDigitalHealth-logo-white-small.png' alt='logo' />
	  	<span style={{ paddingLeft: `2px` }}>atch Digital Health</span>
	      </Navbar.Brand>
            </Link>
          </Col>
          <Col lg={{ order: 2 }} xs={{ order: 3 }} className="px-lg-0 px-0 md:contents">
            <Navbar.Toggle className="order-last md:ml-[17px] sm:ml-0 md:px-[25px]">
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
              <span className="navbar-toggler-line"></span>
            </Navbar.Toggle>
            <Navbar.Collapse className="col-auto col-lg-8 px-lg-0 mx-auto justify-center">
              <ul className="navbar-nav alt-font text-white">
                <li className="nav-item">
                  <ScrollTo to="home" offset={80} delay={0} spy={true} smooth={true} duration={800} className="inner-link nav-link">Home</ScrollTo>
                </li>
                <li className="nav-item">
                  <ScrollTo to="about" offset={80} delay={0} spy={true} smooth={true} duration={800} className="inner-link nav-link">About</ScrollTo>
                </li>
                <li className="nav-item">
                  <ScrollTo to="contact" offset={80} delay={0} spy={true} smooth={true} duration={800} className="inner-link nav-link">Contact</ScrollTo>
                </li>
              </ul>
            </Navbar.Collapse>
          </Col>
          <Col lg={{ order: 3 }} xs={{ order: 2 }} className="col-auto col-lg-2 text-end pe-0 font-size-0 !pl-[90px] lg:!pl-[50px] md:!pl-0]">
          </Col>
        </HeaderNav>
      </Header>
      {/* Header End */}
      <SideButtons />
      {/* Section Start */}
      <section style={{ backgroundImage: `url(/assets/img/webp/cdh-1920x1080.png)`, backgroundPosition: `top center`, backgroundSize: `cover`, backgroundRepeat: `no-repeat` }} id="home" className="relative w-[100%] h-[100vh] landscape:md:h-[650px] landscape:sm:h-[450px] md:h-[650px] sm:h-[450px] bg-cover">
        <div className="top-0 left-0 absolute w-full h-full bg-[#001484] opacity-[0.75]"></div>
        <Container className="relative landscape:h-full">
          <Row className="h-[100vh] items-stretch justify-center md:items-center landscape:h-full md:h-[650px] sm:h-[450px]">
            <Col className="justify-center items-center my-0 mx-auto text-center flex-col flex relative">
              {/* Modal Component Start */}
              <CustomModal.Wrapper
                modalBtn={<Buttons ariaLabel="modal btn" type="submit" className="btn-sonar border-0 mb-[30px]" themeColor="#fc4b08" color="#000" size="md" title={<i className="icon-control-play" />} />} >
                <div className="w-[1020px] max-w-full relative rounded mx-auto">
                  <div className="fit-video">
	  	    <iframe src="https://www.youtube.com/embed/8i1aB0RGex4?si=pDPQq8Gs1AqAEn68" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen" allowfullscreen className="shadow-[0_0_8px_rgba(0,0,0,0.06)]" controls title="Agile Intelligence"></iframe>
                  </div>
                </div>
              </CustomModal.Wrapper>
              {/* Modal Component End */}
              <h1 className="text-shadow text-shadow-large tracking-[-4px] text-[65px] leading-[120px] font-serif text-white lg:text-[110px] md:text-[90px] md:leading-[85px] sm:text-[50px] sm:leading-[50px]">
                Agile Intelligence<sup><small>&#8480;</small></sup>
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section id="about" className="py-[160px] bg-[#001484] overflow-hidden lg:pb-0 lg:pt-[120px] md:pt-[90px] sm:py-[80px] xs:pt-[50px]">
        <Container>
          <Row>
            <Col xl={4} lg={5} md={6}>
              <m.h4 className="font-serif text-white -tracking-[1px] sm:text-center xxs:mb-[45px]" {...fadeIn}>
                <span className="block p-0 sm:text-center tracking-[-1px]">Agile Intelligence will improve</span>
                <TextAnime duration={3000} color="#fc4b08" className="rotate-text text-left sm:text-center font-semibold my-0 mx-auto" animation="slide" data={["care path outcomes", "patient engagement", "clinical accuracy", "physician performance"]} />
              </m.h4>
            </Col>
            <m.div className="sm:text-center col-xl-5 col-md-6 offset-xl-2 offset-lg-1" {...{ ...fadeIn, transition: { delay: 0.5 } }}>
              <span className="font-serif font-semibold text-[#fc4b08] block mb-[15px]">About Catch Digital Health</span>
              <p className="text-white w-[95%] leading-[36px] md:w-full">We are introducing groundbreaking advancements, game changing shifts, that we believe will fundamentally transform the very nature and essence of healthcare as we know it today.</p>
	      <p className="text-white w-[95%] leading-[36px] md:w-full">Catch Digital Health envisions a future where Agile Intelligence works in synergy with human expertise to bring about enhanced health outcomes.</p>
            </m.div>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section id="services" className="lg:pt-[120px] md:pt-[95px] sm:pt-0 sm:pb-[75px] xs:pb-[50px] bg-[#001484]">
        <Container fluid>
          <Row>
            <Col>
              <InteractiveBanners04
                grid="sm:justify-center gap-y-10"
                data={InteractiveBannersData}
                animation={fadeIn}
              />
            </Col>
          </Row>
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section id="contact" className="bg-[#001484] py-[160px] lg:py-[120px] md:py-[95px] border-t border-[#ffffff1a] sm:py-[80px] xs:pt-[50px] xs:pb-0">
        <Container>
          <div className="OnePageNavigationPage-fancytext-box">
	    <Row className="justify-center">
              <Col className="mb-[4.5rem] text-center">
                <span className="mb-[5px] tracking-[-0.5px] text-xmd font-serif block">
                  We appreciate your interest in Catch Digital Health.
                </span>
                <h4 className="font-semibold text-white font-serif ">
                  Join us in achieving our mission!
                </h4>
              </Col>
            </Row>
            <Row className="justify-center fancy-text-box-02">
              <Col xs={12} sm={8} md={12}>
                <FancyTextBox
                  grid="row-cols-1 row-cols-lg-3 row-cols-md-2 justify-center"
                  theme="fancy-text-box-02"
                  data={fancyTextBox02}
                  themeColor="dark"
                  animation={fadeIn}
                />
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      {/* Section End */}

      {/* Footer Start */}
      <footer className="bg-[#001484] text-[#828282] py-[50px] border-t border-[#ffffff1a]">
        <Container>
          <Row className="items-center">
            <Col lg={{ span: 5, order: 1 }} md={{ span: 6, order: 2 }} sm={{ order: 2 }} xs={{ order: 2 }} className="lg:px-0 sm:mb-[15px]">
              <ul className="flex lg:justify-center md:justify-start sm:justify-center xs:text-center xs:flex-col">
                <li className="nav-item mr-[35px] md:mr-[20px] xs:mb-[7px] mb-0">
                  <ScrollTo to="about" offset={80} delay={0} spy={true} smooth={true} duration={800} className="nav-link text-[#828282]">About</ScrollTo>
                </li>
              </ul>
            </Col>
            <Col lg={{ span: 2, order: 2 }} sm={{ order: 1 }} xs={{ order: 1 }} className="order-1 text-center md:mb-[20px]">
              <Link aria-label="footer home link" to="/" className="flex justify-center">Catch Digital Health</Link>
            </Col>
            <Col lg={5} md={6} className="order-3 text-center md:text-end">
              <p className="text-right sm:text-center">&copy; 2025 CMH Acquisitions, LLC</p>
            </Col>
          </Row>
        </Container>
      </footer>
      {/* Footer End */}
    </div>
  );
};

export default OnePageNavigationPage;
